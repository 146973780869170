import React from 'react';
import Form from './Form';
import ImageCarousel from './Caroussel2';

function LandingPage() {
  return (
    <div className="px-4 md:px-15 bg-[#f1e9cf]" itemScope itemType="https://schema.org/WebPage">
      {/* Section 2 : Explication sur la réflexologie */}
      <section id="reflexologie" className="pt-20 -mt-16" itemScope itemType="https://schema.org/Service">
        <h2 className="text-3xl font-bold text-center mb-8" itemProp="name">Être Réflexologue</h2>

        <div className="flex flex-col md:flex-row justify-center items-center mx-auto my-2">
          <div
            className="w-full md:w-1/3 p-6 bg-white rounded-lg shadow-md mx-4"
            itemScope
            itemProp="hasOfferCatalog"
            itemType="https://schema.org/ItemList"
          >
            <h3 className="font-semibold mb-2" itemProp="name">Qu'est-ce que c'est ?</h3>
            <p className="mb-4" itemProp="description">
              La réflexologie, pratiquée par les réflexologues, est une thérapie douce qui vise à équilibrer le corps et à promouvoir le bien-être. 
              Elle se base sur le principe que certaines zones des pieds et des mains sont liées à des organes et systèmes du corps.
            </p>
            <h3 className="font-semibold mb-2">Bienfaits d'une séance avec un réflexologue:</h3>
            <ul className="list-disc list-inside">
              <li itemProp="itemListElement">Réduction du stress</li>
              <li itemProp="itemListElement">Amélioration de la circulation sanguine</li>
              <li itemProp="itemListElement">Récupération après un effort physique</li>
            </ul>
          </div>

          <div className="w-full md:w-1/4 mx-4 my-2 flex justify-center">
            <img
              src="/pied-1.jpg"
              alt="Reflexologue"
              className="w-full rounded-2xl"
              itemProp="image"
            />
          </div>
        </div>
      </section>

      {/* Section 3 : Qui suis-je ? */}
      <section id="qui-suis-je" className="pt-20 -mt-16" itemScope itemType="https://schema.org/Person">
        <h1 className="text-3xl font-bold text-center mb-8">
          <span itemProp="name">Claudine Bouet</span>, Réflexologue entre Bayeux et Caen
        </h1>

        <div className="flex flex-col md:flex-row justify-center items-center">
          <div className="w-full md:w-1/4 mx-2 my-2">
            <img
              src="/claudine-bouet-reflexologue-caen-bayeux.jpg"
              alt="Claudine Bouet Reflexologue Caen Bayeux"
              className="w-full rounded-2xl h-auto"
              itemProp="image"
            />
          </div>
          <div className="w-full md:w-1/2 p-6 bg-white rounded-lg shadow-md mx-2 my-2">
            <h3 className="font-semibold mb-2">Qui suis-je ?</h3>
            <p className="mb-4" itemProp="description">
              Passionnée de médecine douce depuis très longtemps, il a été normal, en 2015, que je me tourne pour soulager mon mal de dos vers la réflexologie plantaire. Les séances rapprochées travaillent mes douleurs qui s’estompent. Très vite, je n’ai plus besoin que de séances plus éloignées pour entretenir ma santé et me détendre… je les attends toujours avec impatience !
              Aussi, quand la vie me donne l’occasion de me former et de devenir réflexologue, je n’hésite pas : la réflexologie plantaire offre tellement de bien-être et de soulagement que c’est décidé, moi aussi je veux faire du bien autour de moi !
              Je me forme pendant 8 mois, selon la méthode INGHAM, alternant les cours théoriques et la pratique sur des pieds volontaires « connus » tout d’abord puis bien vite sur des « nouveaux » pieds, le bouche à oreille ayant fonctionné !
              Les retours positifs ne se font pas attendre : meilleur sommeil, détente, soulagement des douleurs, et les enfants ne sont pas en reste avec une meilleure concentration et des maux de ventre envolés…
              En complément, je suis aussi des cours de diététique et de nutrition, convaincue depuis toujours que notre santé se trouve aussi dans notre assiette. Biochimiste de formation, ces cours complètent mes connaissances et me permettent de conseiller une alimentation saine et équilibrée, abordable par tous.
              Installée entre bayeux et Caen, je suis heureuse d’apporter dans la vie des personnes qui viennent me voir du soulagement, de la détente et du bien-être…
            </p>
          </div>
        </div>
      </section>

      {/* Section 4 : Bien-être au travail */}
      <section id="bien-etre-au-travail" className="pt-20 -mt-16">
        <h2 className="text-3xl font-bold text-center mb-4">Qualité de vie au travail</h2>
        <div className="flex flex-wrap justify-center space-x-4">
        <div className="w-full md:w-1/2 p-6 rounded-lg  mx-2 my-2">
            <p className="mb-4" itemProp="description">
              Pour permettre aux entreprises d'aider leurs collaborateurs à lâcher prise, améliorant ainsi le climat interne et la relation clients.
              C'est également un moyen de prévenir l'absentéisme et de rendre l'entreprise encore plus attractive.

              Je reçois dans mon cabinet à Audrieu et j'interviens en entreprise privée et en structure publique depuis 2016. Pourquoi
              pas dans votre lieu de travail ?
            </p>
        </div>
          <ImageCarousel />
          <div className="w-full md:w-1/2 p-6 rounded-lg  mx-2 my-2">
            <p className="mb-4" itemProp="description">
              Chaque semaine, chaque mois, en fin d'année, avant les vacances, pour remercier, c'est au choix!
              Contactez moi pour en parler.
            </p>
        </div>
        </div>
      </section>

      {/* Section 5 : Contact */}
      <section id="contact" className="pt-20 -mt-16" itemScope itemType="https://schema.org/ContactPoint">
        <h2 className="text-3xl font-bold text-center mb-8">Réserver une séance de réflexologie</h2>
        <Form />
      </section>
    </div>
  );
}

export default LandingPage;
