import React, { useState } from 'react';
import emailjs from 'emailjs-com';

function Form() {
  const [formData, setFormData] = useState({
    name: '',
    subject:'',
    email: '',
    message: '',
  });
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true); // Démarre l'envoi

    try {
      const response = await emailjs.send(
        "service_kr0s6rl",
        "template_49afyf3",
        {
          subject: formData.subject, // Ou un autre champ si nécessaire
          fullname : formData.name,
          emailexp: formData.email,
          emaildest: "scbouet14@gmail.com",
          message: formData.message,
        },
        "JH80-QQOpln2YAmCU"
      );

      if (response.status === 200) {
        alert("Le courriel a bien été envoyé");
        setFormData({ name: '', subject:'',email: '', message: '' }); // Réinitialiser le formulaire après envoi
      } else {
        alert("Échec de l'envoi du courriel. Veuillez réessayer.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email : ", error);
      alert("Une erreur est survenue. Veuillez réessayer.");
    } finally {
      setIsSending(false); // Fin de l'envoi
    }
  };

  return (
    <div className="container mx-auto flex flex-col md:flex-row gap-6 p-6">
      {/* Bloc d'instructions */}
      <div className="bg-white w-full md:w-1/3 p-4 rounded shadow-md">
        <h3 className="text-lg font-semibold mb-2">Situé à Audrieu, entre Bayeux et Caen </h3>
      <a
        href="tel:+33616611197"
        className="text-[#888888] hover:text-[rgb(81,197,17)]"
      >
        06 16 61 11 97
      </a>
      <br/>
      <a
        href="mailto:scbouet14@gmail.com"
        className="text-[#888888] hover:text-[rgb(81,197,17)]"
      >
        scbouet14@gmail.com
      </a>
        <br />
        <h3 className="text-lg font-semibold mb-2">Claudine Bouet, Réflexologue</h3>
        <p>3, rue Jody Williams</p>
        <p>14250 AUDRIEU</p>
        <br />
        <h3 className="text-lg font-semibold mb-2">Séances - sur rendez-vous</h3>
        <p>Du lundi au vendredi de 9h à 19h</p>
        <p>Un samedi matin par mois de 9h à 12h</p>
      </div>

      {/* Formulaire */}
      <form onSubmit={handleSubmit} className="bg-white w-full md:w-2/3 p-6 rounded shadow-md">
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="name">Nom<span className="text-red-500"> *</span></label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="name">Sujet<span className="text-red-500"> *</span></label>
          <input
            type="text"
            name="subject"
            id="subject"
            value={formData.subject}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="email">Email<span className="text-red-500"> *</span></label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold mb-1" htmlFor="message">Message<span className="text-red-500"> *</span></label>
          <textarea
            name="message"
            id="message"
            value={formData.message}
            onChange={handleChange}
            className="w-full border border-gray-300 p-2 rounded"
            rows="4"
            required
          ></textarea>
        </div>
        <button type="submit" className="bg-[rgb(81,197,17)] text-white font-semibold py-2 px-4 rounded hover:bg-green-700 transition duration-200" disabled={isSending}>
          {isSending ? "Envoi en cours..." : "Envoyer"}
        </button>
      </form>
    </div>
  );
}

export default Form;
