import React from 'react';

function Footer() {
  return (
      <footer className="bg-[#824d0d] text-white py-10 px-1s0">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Colonne 1 : Infos de contact */}
          <div className='mx-auto'>
            <h3 className="text-lg font-semibold mb-2">Claudine Bouet</h3>
            <p>16, rue Jody Williams</p>
            <p>14250 AUDRIEU</p>
            <p>06 16 61 11 97</p>
            <p>scbouet14@gmail.com</p>
          </div>

          {/* Colonne 2 : Image */}

          <div className='align-middle mt-6'>
            <img src="./icon_blanc.png" alt="Réflexologue, réflexologie" className="w-24 h-auto mx-auto" />
          </div>

          {/* Colonne 3 : Carte*/}
          <div className='mx-auto'>
          <h3 className="text-lg font-semibold mb-2">Ou me trouver ?</h3>
            <iframe 
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5212.916066973906!2d-0.589753!3d49.21084!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x480a51e7ccc7054d%3A0x497d1b82d76defa0!2s16%20Rue%20Jody%20Williams%2C%2014250%20Audrieu%2C%20France!5e0!3m2!1sfr!2sca!4v1729970244577!5m2!1sfr!2sca" 
                title="Ou me trouver"
                allowFullScreen="" 
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade">

            </iframe>
            </div>
        </div>
      </footer>
  );
}

export default Footer;
