import React, { useEffect, useState } from 'react';

function Accueil() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Affiche le texte après un délai
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 500); // Ajustez le délai ici si nécessaire

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {/* Section 1 : Image d'introduction */}
      <section className="relative h-screen flex items-center justify-center overflow-hidden">
        <img src="/zen-1.jpg" alt="fond représentant une fleure zen dans un salon de réflexologie" className="absolute inset-0 w-full h-full object-cover" />
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10">
          <p className={`text-5xl font-bold z-10 text-white text-center italic transition-transform duration-700 ${isVisible ? 'translate-y-0 opacity-100 animate-slideIn' : 'translate-y-10 opacity-0'} leading-relaxed mb-4`}>
            <span>"Osez la santé </span>
            <span className="bg-white text-[rgb(81,197,17)] px-2">Autrement</span>
            <span>"</span>
          </p>
        </div>
      </section>
    </div>
  );
}

export default Accueil;
