import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import image1 from "../images/carte-des-pieds[1].jpg"; // Ajustez les chemins si nécessaire
import image2 from "../images/image sophro.gif";
import image3 from "../images/head-massage.jpg";
import image4 from "../images/Réflexologie.jpg";

const ImageCarousel = () => {
  // Données des slides
  const slides = [
    {
      image: image1,
      title: "Réflexologie Crano-faciale",
      description:
        "Pour se détendre (le stress), se ressourcer (la fatigue), pour la concentration (le mental), pour rebooster le système immunitaire et relâcher les tensions physiques et psychologiques. ",
    },
    {
      image: image2,
      title: "Réflexologie crano-faciale",
      description:
        "La mise en place de ces séances est très simple. J'apporte le matériel nécessaire (fauteil, coussin plaid, baume, musique douce) il suffit d'une petite pièce pour m'installer. " +
        "Au choix des séeances de 30 min, 50 min ou 1h en fonction de la discipline choisie. Sur le temps du travail, sur le temps du midi, je me déplace pour 2h minimum. " +
        "En réflexologie pkantaire ou crano-faciale, l'approche est la même et la détente est au rendez-vous!",
    },
    {
      image: image3,
      title: "Sophro-Relaxation",
      description:
        "Pour permettre à tous de mieux gérer notre vie au quotidien, de transmettre des outils de gestion du stress utilisables en tous lieux et en toute autonomie grâce à "+
        "une détente physique et mentale, une prise de recul face aux difficultés et un certain lâcher prise.",
    },
    {
      image: image4,
      title: "Atelier Sophro-relaxation",
      description:
        "Les ateliers de sophro-relaxation utilisent des outils simples, ludiques et efficaces pour aider chacun à s'épanouir en développant le vivre ensemble. Chacun va ainsi apprendre à renforcer"+
        "renforcer son attention et sa concentration, gérer son stress et ses émotions, améliorer la confiance en soi et évacuer les tensions. La détente apportée va développer la créativité et augmenter"+
        "la capacité de voir le positif dans une activité."+
        "La mise en place de ces séances d'1h est très simple, il suffit d'une salle disponible pour que le groupe (entre 2 et 12 personnes) puisse se réunir. Prévoir un tapis et un plaid pour le confort."+
        "Mais les séances peuvent également se faire assis ou debout",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

    // Add these new states for touch handling
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
  
    // Minimum swipe distance in pixels
    const minSwipeDistance = 50;
  
    const handleTouchStart = (e) => {
      setTouchStart(e.touches[0].clientX);
      setIsPaused(true); // Pause while touching
    };
  
    const handleTouchMove = (e) => {
      setTouchEnd(e.touches[0].clientX);
    };
  
    const handleTouchEnd = () => {
      if (!touchStart || !touchEnd) return;
  
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
  
      if (isLeftSwipe) {
        nextSlide();
      } else if (isRightSwipe) {
        prevSlide();
      }
  
      // Reset
      setTouchEnd(null);
      setTouchStart(null);
      setIsPaused(false); // Resume animation
    };
  
  // Fonction pour aller à la slide suivante
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  // Fonction pour aller à la slide précédente
  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  useEffect(() => {
    if (isPaused) return; // Ne pas démarrer l'intervalle si en pause
    const interval = setInterval(nextSlide, 10000); 
    return () => clearInterval(interval);
  }, [isPaused, currentIndex]);
  

  return (
    <div className="relative w-full overflow-hidden flex justify-center items-center">
      <button
        onClick={prevSlide}
        className="absolute left-4 top-40 z-10 bg-white p-2 rounded-full shadow-md hover:scale-110 hidden sm:block"
      >
        ◀
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-4 top-40 z-10 bg-white p-2 rounded-full shadow-md hover:scale-110 hidden sm:block"

      >
        ▶
      </button>

      <div className="relative w-full flex justify-center items-center overflow-hidden">
        <AnimatePresence mode="wait">
          {slides[currentIndex] && (
            <motion.div
              key={currentIndex}
              onMouseEnter={() => setIsPaused(true)}
              onMouseLeave={() => setIsPaused(false)}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              initial={{ opacity: 0, x: 300 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -300 }}
              transition={{ duration: 0.6, ease: "easeInOut" }}
              className="w-[95%] max-w-2xl mx-auto bg-white rounded-xl shadow-lg overflow-hidden touch-pan-y p-6"
            >
              {/* Image Container */}
              <div className="w-64 max-h-[300px] mb-4 mx-auto rounded-lg overflow-hidden">
                <img
                  src={slides[currentIndex].image}
                  alt={slides[currentIndex].title}
                  className="w-full h-full object-contain"
                />
              </div>
              
              {/* Text Content */}
              <div className="text-center px-4">
                <h3 className="text-2xl font-bold mb-3" style={{ color: 'rgb(81, 197, 17)' }}>
                  {slides[currentIndex].title}
                </h3>
                <p className="text-gray-600 text-sm">
                  {slides[currentIndex].description}
                </p>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default ImageCarousel;
